import { FunctionComponent } from "react"
import styled from "@emotion/styled"

import {
  Body,
  TextLink,
  GridRow,
  vertical,
  column,
  breakpoints,
} from "design-kit"
import sania from "./assets/square-sania.jpg"

const TextHalf = styled.div`
  ${column({
    widthMobile: 4,
    widthTablet: 3,
    widthDesktop: 6,
  })}
  padding-top: ${vertical.xl};
  padding-bottom: ${vertical.l};

  ${breakpoints.tablet`
    padding-top: 200px;
    padding-bottom: 0;
  `}
`

const ImageHalf = styled.div`
  ${column({
    widthMobile: 4,
    widthTablet: 3,
    widthDesktop: 6,
  })}
  max-height: 400px;
  overflow: hidden;
  margin-bottom: ${vertical.xs};

  ${breakpoints.tablet`
    max-height: 600px;
    margin-top: ${vertical.m};
    margin-bottom: ${vertical.m};
  `}
`

const Img = styled.img`
  margin-top: -140px;
  margin-left: -200px;

  ${breakpoints.tablet`
    margin-left: -100px;
  `}
`

const Section = styled.section`
  display: flex;
`

const BodyWithMargin = styled(Body)`
  margin-top: ${vertical.m};
`

const Intro: FunctionComponent = () => {
  return (
    <Section>
      <GridRow>
        <TextHalf>
          <BodyWithMargin>
            Mortgages are broken. For too long, they’ve disempowered, confused
            and excluded people. And in the UK, those who do have a mortgage pay
            £15.5 billion too much a year.
          </BodyWithMargin>

          <BodyWithMargin>
            We’re here to fix mortgages and rebuild home financing from the
            ground up. To put the customer firmly back in control.
          </BodyWithMargin>

          <BodyWithMargin>
            <TextLink
              text="Join us."
              href="https://www.linkedin.com/company/habito-/jobs/"
            />
          </BodyWithMargin>
        </TextHalf>
        <ImageHalf>
          <Img
            src={sania}
            alt="Smiling lady in the habito office, working, having a good time."
          />
        </ImageHalf>
      </GridRow>
    </Section>
  )
}

export default Intro
