import { FunctionComponent } from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"

import {
  GridRow,
  Headline1,
  SecondaryLink,
  breakpoints,
  colours,
  column,
  vertical,
  horizontal,
} from "design-kit"
import blueGradientMobile from "./assets/background-blue-mobile.png"
import blueGradientTablet from "./assets/background-blue-tablet.jpg"
import cloud from "./assets/cloud-small.svg"
import keys from "./assets/keys-heavenly.svg"
import angryCloud from "./assets/cloud-angry-1.svg"
import angryCloudTwins from "./assets/cloud-angry-2.svg"

const HeaderContainer = styled.section`
  background-image: url(${blueGradientMobile});
  background-position-x: 20%;
  background-repeat: no-repeat;
  background-size: cover;
  color: ${colours.white};
  padding: calc(${vertical.xl} + ${vertical.xl}) ${horizontal.m} ${vertical.xl};

  ${breakpoints.tablet`
    background-image: url(${blueGradientTablet});
    background-position-x: unset;
    padding: calc(${vertical.xl} + 120px + ${vertical.xl}) ${horizontal.m} ${vertical.xl}; /* padding-top is calc({navbar + space for clouds + normal padding}) */
  `}
`

const HeaderInner = styled.div`
  ${column({
    widthMobile: 4,
    widthTablet: 6,
    widthDesktop: 12,
  })}
  display: flex;
  flex-direction: column;
  max-width: 784px;
  margin: 0 auto;

  ${breakpoints.tablet`
    flex-direction: row;
  `}

  ${breakpoints.desktop`
    max-width: 1168px;
  `}
`

const RelativeDiv = styled.div`
  position: relative;
`

const AngryCloudTwins = styled.img`
  display: none;

  ${breakpoints.tablet`
    display: block;
    position: absolute;
    top: -96px;
    left: 40px;
  `}
`

const AngryCloud = styled.img`
  display: none;

  ${breakpoints.tablet`
    display: block;
    position: absolute;
    top: -56px;
    left: 280px;
  `}
`

const KeysAndCloud = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  ${breakpoints.tablet`
    order: 2;
  `}
`

const Keys = styled.img`
  max-width: 160px;

  ${breakpoints.desktop`
    max-width: 280px;
    margin-top: -120px;
  `}
`

const Cloud = styled.img`
  max-width: 80px;

  ${breakpoints.desktop`
    max-width: 160px;
  `}
`

const Header: FunctionComponent = () => {
  return (
    <HeaderContainer>
      <GridRow>
        <HeaderInner>
          <KeysAndCloud>
            <Keys src={keys} alt="" />
            <Cloud src={cloud} alt="" />
          </KeysAndCloud>
          <RelativeDiv>
            <AngryCloudTwins src={angryCloudTwins} alt="" />
            <AngryCloud src={angryCloud} alt="" />
            <Headline1
              css={css`
                margin-bottom: ${vertical.s};
              `}
            >
              Help us set people free from the hell of mortgages
            </Headline1>
            <SecondaryLink
              text="See our jobs"
              href="https://www.linkedin.com/company/habito-/jobs/"
              width="content-width"
            />
          </RelativeDiv>
        </HeaderInner>
      </GridRow>
    </HeaderContainer>
  )
}

export default Header
