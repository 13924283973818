import { FunctionComponent } from "react"
import styled from "@emotion/styled"

import {
  GridRow,
  Body,
  Headline2,
  breakpoints,
  colours,
  column,
  typographyStyles,
  vertical,
  horizontal,
} from "design-kit"

const benefits = [
  {
    title: "Flexible holiday",
    text: "Take your holiday in whatever way works for you.",
  },
  {
    title: "Inspiring guest speakers",
    text: "Meet inspiring people, like Stacey Copeland, the first ever British female boxer to win the Commonwealth.",
  },
  {
    title: "Life insurance",
    text: "4x your annual salary for your loved ones.",
  },
  {
    title: "Pension",
    text: "We pay 3%, you pay 5% (or however much you want).",
  },
  {
    title: "Cycle to work",
    text: "Buy a bike, lights and helmet tax-free, and pay them off in installments every month.",
  },
  {
    title: "Enhanced maternity and paternity leave",
    text: "Spend more time with your new family.",
  },
  {
    title: "Remote work",
    text: "Work from home, with the option to go to the office (subject to desk availability).",
  },
]

const BenefitsSection = styled.section`
  background-color: ${colours.greyScale.grey25};
`

const BenefitsInner = styled.div`
  ${column({
    widthMobile: 4,
    widthTablet: 6,
    widthDesktop: 12,
  })};
  padding: ${vertical.xl} 0;
  margin: 0 ${horizontal.m};
`

const Headline2Styled = styled(Headline2)`
  color: ${colours.offBlack};
  padding: 0 8px;
  margin-bottom: ${vertical.s};
`

const Headline3Styled = styled.h3`
  ${typographyStyles.headline2};
  color: ${colours.offBlack};
  margin-bottom: ${vertical.xs};
`

const BenefitsList = styled.ul`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`

const BenefitItem = styled.li`
  width: 100%;
  box-sizing: border-box;
  padding: 0 ${horizontal.xs} ${vertical.xs};

  ${breakpoints.tablet`
    width: 50%;
  `}

  ${breakpoints.desktop`
    width: 33%;
  `}
`

const BenefitItemInner = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colours.white};
  color: ${colours.offBlack};
  height: 100%;
  box-sizing: border-box;
  border-radius: 4px;
  padding: ${vertical.l} ${horizontal.l};
`

const TheBenefits: FunctionComponent = () => {
  return (
    <BenefitsSection>
      <GridRow>
        <BenefitsInner>
          <Headline2Styled>The benefits</Headline2Styled>
          <BenefitsList>
            {benefits.map(benefit => {
              return (
                <BenefitItem key={benefit.title}>
                  <BenefitItemInner>
                    <Headline3Styled>{benefit.title}</Headline3Styled>
                    <Body>{benefit.text}</Body>
                  </BenefitItemInner>
                </BenefitItem>
              )
            })}
          </BenefitsList>
        </BenefitsInner>
      </GridRow>
    </BenefitsSection>
  )
}

export default TheBenefits
