import { FunctionComponent } from "react"
import styled from "@emotion/styled"

import {
  GridRow,
  Body,
  Headline2,
  PrimaryLink,
  breakpoints,
  colours,
  column,
  typographyStyles,
  vertical,
  horizontal,
} from "design-kit"

const ValuesSection = styled.section`
  ${column({
    widthMobile: 4,
    widthTablet: 6,
    widthDesktop: 12,
  })};
  padding: ${vertical.xl} 0;
  margin: 0 ${horizontal.m};
`

const Headline2Styled = styled(Headline2)`
  color: ${colours.offBlack};
  padding: 0 8px;
  margin-bottom: ${vertical.s};
`

const ValuesList = styled.ul`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: ${vertical.xl};
`

const ValueItem = styled.li`
  width: 100%;
  box-sizing: border-box;
  padding: 0 ${horizontal.xs} ${vertical.xs};
  box-sizing: border-box;

  ${breakpoints.tablet`
  width: 50%;
  `}
`

const ValueItemInner = styled.div`
  background-color: ${colours.offBlack};
  border-radius: 4px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: ${vertical.l} ${horizontal.l};
`

const Headline3Styled = styled.h3`
  ${typographyStyles.headline2};
  color: ${colours.constructive.main};
  margin-bottom: ${vertical.xs};
`

const BodyStyled = styled(Body)`
  color: ${colours.white};
`

const CTACentrer = styled.div`
  display: flex;
  justify-content: center;
`

const OurValues: FunctionComponent = () => {
  return (
    <GridRow>
      <ValuesSection>
        <Headline2Styled>Our values</Headline2Styled>
        <ValuesList>
          <ValueItem>
            <ValueItemInner>
              <Headline3Styled>Empathise with purpose</Headline3Styled>
              <BodyStyled>
                Understand what customers really need. Unite together around
                those needs.
              </BodyStyled>
            </ValueItemInner>
          </ValueItem>
          <ValueItem>
            <ValueItemInner>
              <Headline3Styled>Build better things</Headline3Styled>
              <BodyStyled>
                Our ideal product = our customer’s ideal experience. We iterate
                fearlessly to get there.
              </BodyStyled>
            </ValueItemInner>
          </ValueItem>
          <ValueItem>
            <ValueItemInner>
              <Headline3Styled>Be 100% responsible</Headline3Styled>
              <BodyStyled>Your loss is my loss. My win is your win.</BodyStyled>
            </ValueItemInner>
          </ValueItem>
          <ValueItem>
            <ValueItemInner>
              <Headline3Styled>Have backbone</Headline3Styled>
              <BodyStyled>
                Fearless in everything: from speaking up, to backing down, to
                shaking a whole industry.
              </BodyStyled>
            </ValueItemInner>
          </ValueItem>
        </ValuesList>
        <CTACentrer>
          <PrimaryLink
            text="More about our culture"
            href="/culture"
            width="content-width"
          />
        </CTACentrer>
      </ValuesSection>
    </GridRow>
  )
}

export default OurValues
