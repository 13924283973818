import { FunctionComponent } from "react"
import styled from "@emotion/styled"

import { breakpoints } from "design-kit"
import mudders from "./assets/benefits-tough-mudder.jpg"
import allHands from "./assets/square-all-hands.jpg"

const FlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const BottomImageContainer = styled.div`
  width: 100%;
  max-height: 400px;
  overflow: hidden;

  ${breakpoints.tablet`
    width: 50%;
  `}
`

const BottomImage = styled.img`
  width: 100%;
  ${breakpoints.desktop`
    margin-top: -120px;
  `}
`

const BottomImages: FunctionComponent = () => {
  return (
    <FlexContainer>
      <BottomImageContainer>
        <BottomImage
          src={allHands}
          alt="Habito's CEO, Dan speaking at the all hands meeting to a room of people eagerly watching."
        />
      </BottomImageContainer>
      <BottomImageContainer>
        <BottomImage
          src={mudders}
          alt="A group of 13 Habitonians ready to participate in the Tough Mudder endurance event series."
        />
      </BottomImageContainer>
    </FlexContainer>
  )
}

export default BottomImages
